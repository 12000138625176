import React from "react";
import styled from "@emotion/styled";
import t from "../helper/t";
import SmartLink from "../components/elements/SmartLink";

const Container = styled.div([
  `
  background-color: #EBEBEB;
  height: 300px;
  text-align: center;
  padding: 100px 20px 20px 20px;
  `
]);

const Custom404Component: React.FC<{}> = () => {
  return (
    <Container>
      <h1>{t("Seite nicht gefunden (404)")}</h1>
      <p style={{ marginBottom: 60 }}>{t("Entschuldigung, die gewünschte Seite konnte nicht gefunden werden.")}</p>
      <SmartLink href="/">
        {t("Hier geht es zur Startseite zurück.")}
      </SmartLink>
    </Container>
  )
}

export default Custom404Component;