import styled from "@emotion/styled";
import Navbar from "./elements/Navbar";
import NavigationButton from "./elements/NavigationButton";
import { RedButtonDiv } from "./elements/Buttons";
import t from "../../helper/t";

import bg404Webp from "../../../assets/images/splus3/bg404.webp";

const ContainerDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        height: "100vh",

        background: `url(${bg404Webp.src}), lightgray 50%`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `center center`,
        backgroundSize: "cover",
    }
]);

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 64,
        alignItems: "center",
        rowGap: 16,
        flexGrow: 10,
    }
]));

const TopTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.font24Bold,
    {
        background: "linear-gradient(90deg, #CB0000 1.53%, #E60000 99.79%)",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
        lineHeight: "150%",
        textAlign: "center",
    }
]));

const TitleTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.font36Bold,
    theme.splus3.foreground.color404,
    {
        lineHeight: "150%",
    }
]));

const BodyTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.large,
    theme.splus3.foreground.color404,
    {
        lineHeight: "150%",
    }
]));

const Custom404Component: React.FC = () => {
    return (
        <ContainerDiv>
            <Navbar hideMenu={true} />
            <RootDiv>
                <TopTextDiv>{t("404 Not Found")}</TopTextDiv>
                <TitleTextDiv>{t("Seite nicht gefunden.")}</TitleTextDiv>
                <BodyTextDiv>{t("Entschuldigung, die gewünschte Seite konnte nicht gefunden werden.")}</BodyTextDiv>
                <div style={{ marginTop: 8 }} />
                <NavigationButton href="/">
                    <RedButtonDiv>
                        {t("Zurück zur Startseite")}
                    </RedButtonDiv>
                </NavigationButton>
            </RootDiv>
        </ContainerDiv>
    );
}

export default Custom404Component;
