import React, { useState } from "react";
import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import NavigationButton from "./NavigationButton";
import { updatePage } from "../../../features/pageManager/pageManagerSlice";
import { PageKey } from "../../../features/pageManager/pageState";
import Underline from "./Underline";
import t from "../../../helper/t";
import { isSpeedtestRunning } from "../../../features/measurement/utils";
import { sentryReportsCancellationDialogOpened } from "../../../features/sentryReports/sentryReportsSlice";
import { showCancellationDialog } from "../../../features/dialogManager/dialogManagerSlice";

import imageNewVfIconRgbRed from "../../../../assets/images/New_VF_Icon_RGB_RED.svg";
import menuSvg from "../../../../assets/icons/splus3/menu.svg";
import closeSvg from "../../../../assets/icons/splus3/close.svg";

const NavDiv = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.shadow.primary,
    {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        display: "flex",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                paddingLeft: 16,
                paddingRight: 16,
            }
        ],
    }
]));

const LogoContentDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        alignItems: "center",
        width: "8.33vw",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            width: "auto",
        },
    }
]));

const NavLogoImg = styled.img([
    {
        display: "flex",
        width: 48,
    }
]);

const ButtonsContainer = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        columnGap: 32,
        rowGap: 16,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            display: "none"
        },
    }
]));

const MenuContainer = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.background.white,
    theme.splus3.typography.default,
    {
        display: "none",
        alignItems: "center",
        columnGap: 12,
        lineHeight: "28px",
        marginLeft: 12,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            display: "flex"
        },
    }
]));

interface MenuListContainerProps {
    isOpened: boolean;
}

const MenuListContainer = styled.div<MenuListContainerProps>(({ theme, isOpened }) => ([
    theme.splus3.background.defaultTransparent,
    {
        position: "absolute",
        left: 0,
        top: 71,
        width: "100%",
        height: isOpened ? "calc(100% - 71px)" : 0,
        paddingTop: 0,
        overflow: "hidden",
        transition: "height 0.2s ease-out",
        display: "none",
        zIndex: 1000,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: {
            display: "flex"
        },
    }
]));

const MenuList = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    theme.splus3.shadow.primary,
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: 16,
        rowGap: 20,
        flexGrow: 10,
        // borderRadius: "0px 6px 6px 0px",
    }
]));

const CloseMenuButton = styled.div(({ theme }) => ([
    theme.splus3.background.monochrome600,
    {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 6,
        margin: 8,
        width: 44,
        height: 44,
    }
]));

interface ButtonContentProps {
    isSelected: boolean;
}

const ButtonContent = styled.span<ButtonContentProps>(({ theme, isSelected }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.large,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            isSelected ? theme.splus3.typography.defaultBold : theme.splus3.typography.defaultLite,
            {
                lineHeight: "28px",
            }
        ],
    },
    isSelected && theme.splus3.typography.bold,
]));

interface NavbarProps {
    hideMenu?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ hideMenu = false }) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.pageManager);
    const measurementState = useAppSelector(state => state.measurement);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const logoClick = () => {
        if (isSpeedtestRunning(measurementState)) {
            dispatch(showCancellationDialog());
            sentryReportsCancellationDialogOpened();
        } else {
            dispatch(updatePage(PageKey.Home));
        }
    }

    const navButton = (name: string, page: PageKey | string, dataCy?: string) => {
        return typeof page === "string" ? (
            <NavigationButton dataCy={dataCy} href={page}>
                <ButtonContent isSelected={false}>
                    {name}
                </ButtonContent>
            </NavigationButton>
        ) : (
            <NavigationButton dataCy={dataCy} onClick={() => dispatch(updatePage(page))} isEnabled={state.currentPage !== page}>
                <ButtonContent isSelected={state.currentPage === page}>
                    {name}
                </ButtonContent>
            </NavigationButton>
        );
    }

    const navButtonM = (name: string, page: PageKey | string) => {
        const isSelected = state.currentPage === page;

        return (
            <div>
                {typeof page === "string" ? (
                    <div onClick={() => setMenuOpen(false)}>
                        <NavigationButton href={page}>
                            <ButtonContent isSelected={false}>
                                {name}
                            </ButtonContent>
                        </NavigationButton>
                    </div>
                ) : (
                    <NavigationButton onClick={() => {
                        setMenuOpen(false);
                        dispatch(updatePage(page));
                    }} isEnabled={!isSelected}>
                        <ButtonContent isSelected={isSelected}>
                            {name}
                        </ButtonContent>
                    </NavigationButton>
                )}
                <Underline isSelected={isSelected} />
            </div>
        );
    }

    return (
        <header>
            {/* Mobile */}
            <MenuListContainer isOpened={isMenuOpen}>
                <MenuList>
                    {navButtonM(t("Startseite"), PageKey.Home)}
                    {navButtonM(t("Deine Messungen"), PageKey.Result)}
                    {navButtonM(t("Was macht der Speedtest Plus?"), PageKey.Info)}
                    {navButtonM(t("Angebote"), "https://zuhauseplus.vodafone.de/internet-telefon/?icmp=speedtest:internettelefon")}
                </MenuList>
                <CloseMenuButton onClick={() => setMenuOpen(false)} tabIndex={0} onKeyDown={(e) => { if (e.key === 'Enter'){setMenuOpen(false)}}}>
                    <img src={closeSvg.src} alt={t("Menü schließen [aria]")} />
                </CloseMenuButton>
            </MenuListContainer>

            {/* Desktop */}
            <NavDiv>
                <LogoContentDiv>
                    <NavigationButton onClick={logoClick}>
                        <NavLogoImg src={imageNewVfIconRgbRed.src} alt="Vodafone-logo" />
                    </NavigationButton>
                </LogoContentDiv>

                {hideMenu !== true && (
                    <>
                        <ButtonsContainer>
                            {navButton(t("Startseite"), PageKey.Home)}
                            {navButton(t("Deine Messungen"), PageKey.Result, "deine-messungen-button")}
                            {navButton(t("Was macht der Speedtest Plus?"), PageKey.Info)}
                            {navButton(t("Angebote"), "https://zuhauseplus.vodafone.de/internet-telefon/?icmp=speedtest:internettelefon")}
                        </ButtonsContainer>
                        <MenuContainer onClick={() => setMenuOpen(!isMenuOpen)} tabIndex={0} onKeyDown={(e) => { if (e.key === 'Enter'){setMenuOpen(!isMenuOpen)}}}>
                            <img src={menuSvg.src} alt={t("Menü öffnen [aria]")}/>
                            <span>{t("Menü")}</span>
                        </MenuContainer>
                    </>
                )}
            </NavDiv>
        </header>
    )
};

export default Navbar;

